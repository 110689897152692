<template>
  <div class="mainform">
    <div class="mainHeader">指标细项分配<span @click="back" class="back">返回></span></div>
    <div class="exportData">
      <el-button class="textBtn" @click="AddRework" type="text">+新增</el-button>
    </div>
    <div class="tab-tit">
      <span class="tableHeader">次级指标代码: {{detailCode}}</span>
      <span class="tableHeader">次级指标描述: {{detailName}}</span>
    </div>
    <div class="tableList" >
      <div class="table">
        <el-table :data="tableData" ref="tabledeatailData" style="width: 100%">
          <el-table-column prop="sequenceNumber" label="序号" width="80px" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.sequenceNumber" placeholder=""></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="detailCode" label="细项代码" width="180px" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="detailName" label="细项描述" width="130px" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="detailStandard" label="细项评分标准" width="300px" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="strMode" label="评分方式" width="130px" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-select :disabled=scope.row.showDisabled v-model="scope.row.strMode" placeholder="请选择">
                <el-option label="系统自动" value="1"></el-option>
                <el-option label="手工评分" value="2"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="userApi" label="用户接口" width="120px" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.userApi"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="defaultScore" label="缺省分值" width="120px" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.defaultScore"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="conversionRatio" label="折算比率(%)" width="120px" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.conversionRatio"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="branchStart" label="合理分值从" width="120px" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.branchStart"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="branchEnd" label="合理分值到" width="120px" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.branchEnd"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="detailsPage" label="指标展示明细页面" width="150px" :show-overflow-tooltip="true">
            <template #default="scope">
              <el-input :disabled=scope.row.showDisabled v-model="scope.row.detailsPage"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100px">
            <template #default="scope">
              <el-button v-if="scope.row.edStart === 1" type="text" size="mini"
              @click="edit(scope.row)">编辑</el-button>
              <el-button v-else type="text" size="mini" @click="preservation(scope.row)">保存</el-button>
              <el-button type="text" size="mini" @click="eliminateDinlog(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="tablepage">
          <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size = pageSize
          layout="total,  prev, pager, next, jumper"
          :total = total>
          </el-pagination>
        </div>
      </div>
    </div>
    <dialog-box ref="AddNewData" componentName="IndicatorsAddDialog" dialogWidth="800px" :dialogShow="AddcheckDialogShow" @handleClose='handleClose' title="新增" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'addPrework', type:'primary', size:'mini'}]" @addPrework="peworkBtn"></dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
// import { tableField, tableData } from './js/subcriteriAallot'
import { request } from '@/assets/js/http.js'
export default {
  components: { ...Component.components },
  name: 'Indicatorsdetail',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      editStart: 1,
      parentId: this.$route.query.id,
      detailCode: this.$route.query.code,
      detailName: this.$route.query.name,
      pageNum: 1, // 当前第几页
      pageSize: pageSize, // 每页显示多少条
      total: 0, // 共多少条
      AddcheckDialogShow: false,
      tableData: []
    }
  },
  mounted () {
  },
  created () {
    this.getDataList()
  },
  methods: {
    // ID获取指标细项列表
    getDataList () {
      const parentId = this.parentId
      const obj = {
        parentId
      }
      request('/api/evaluation/detail/queryAll?pageNum=' + this.pageNum + '&' + 'pageSize=' + this.pageSize, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.total = res.data.total
          this.tableData.forEach(item => {
            item.edStart = 1
            item.showDisabled = true
          })
        }
      })
    },

    // 新增弹框
    AddRework () {
      this.AddcheckDialogShow = true
    },
    // 点击新增里面的确认按钮
    peworkBtn () {
      var newsArr = this.$refs.AddNewData.$refs.content.form
      newsArr.edStart = 1
      newsArr.showDisabled = true
      if (!newsArr.sequenceNumber) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入序号'
        })
        return false
      }
      if (!newsArr.detailCode) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入细项代码'
        })
        return false
      }
      if (!newsArr.detailName) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入细项描述'
        })
        return false
      }
      if (!newsArr.detailStandard) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入细项评分标准'
        })
        return false
      }
      if (!newsArr.strMode) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择评分方式'
        })
        return false
      }
      if (!newsArr.defaultScore) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入缺省分值'
        })
        return false
      }
      if (!newsArr.conversionRatio) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入折算比率'
        })
        return false
      }
      if (!newsArr.branchStart) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入开始合理分值'
        })
        return false
      }
      if (!newsArr.branchEnd) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入结束合理分值'
        })
        return false
      }

      const obj = {
        parentId: this.parentId,
        sequenceNumber: newsArr.sequenceNumber,
        detailCode: newsArr.detailCode,
        detailName: newsArr.detailName,
        detailStandard: newsArr.detailStandard,
        strMode: newsArr.strMode,
        userApi: newsArr.userApi,
        defaultScore: newsArr.defaultScore,
        conversionRatio: newsArr.conversionRatio,
        branchStart: newsArr.branchStart,
        branchEnd: newsArr.branchEnd,
        detailsPage: newsArr.detailsPage,
        status: true
      }
      request('/api/evaluation/detail/save', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '添加成功'
          })
          this.getDataList()
        }
      })
      this.AddcheckDialogShow = false
    },
    // 保存
    preservation (row) {
      if (!row.sequenceNumber) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入序号'
        })
        return false
      }
      if (!row.strMode) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择评分方式'
        })
        return false
      }
      if (!row.defaultScore) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入缺省分值'
        })
        return false
      }
      if (!row.conversionRatio) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入折算比率'
        })
        return false
      }
      if (!row.branchStart) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入开始合理分值'
        })
        return false
      }
      if (!row.branchEnd) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入结束合理分值'
        })
        return false
      }
      const obj = {
        ...row
      }
      request('/api/evaluation/detail/update', 'POST', obj).then((res) => {
        if (res.code === '200') {
          row.edStart = 1
          row.showDisabled = true
          // this.editStart = 1
          this.$message({
            showClose: true,
            type: 'success',
            message: '修改成功'
          })
          this.getDataList()
        }
      })
    },

    // 编辑
    edit (row) {
      row.edStart = 2
      row.showDisabled = false
      // this.editStart = 2
    },

    // 删除
    eliminate (row) {
      request('/api/evaluation/detail/delete?id=' + row.id, 'post').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功'
          })
          this.getDataList()
        }
      })
    },
    // 删除弹框
    eliminateDinlog (row) {
      this.$confirm('确认要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.eliminate(row)
      })
    },
    // 跳转指标细项
    scoredetail (data) {
      this.$router.push({
        params: { viewName: 'Indicatorsdetail' },
        query: {
          id: data.id,
          code: data.secondaryCode,
          name: data.secondaryName
        }
      })
    },
    // 关闭弹窗
    handleClose () {
      this.AddcheckDialogShow = false
    },
    // 每页显示多少条
    handleSizeChange (val) {
      this.pageSize = val
      this.getDataList()
    },
    // 当前第几页
    handleCurrentChange (val) {
      this.pageNum = val
      this.getDataList()
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  @import '@/assets/css/form.scss';
  @import '@/assets/css/tableList.scss';
  .exportData{
    padding: 0 30px;
    .el-button{
      font-size: 16px;
    }
  }
  .tab-tit{
    margin: 0 30px 0;
    padding-top: 15px;
    border-top: 1px solid #eee;
  }
  .mainform{
    .tableHeader{
      margin-right: 55px;
      color: #606266;
      font-size: 14px;
    }
    .action{
      margin-top: 20px;
    }
    :deep(.tableList){
      padding: 20px 30px;
    }
  }
  .tableList {
    .table{
      :deep(.el-table td) {
          padding: 4px 0;
      }
      :deep(.el-input__inner){
        height:34px;
        line-height: 34px;
      }
    }
    .is-disabled{
      :deep(.el-input__inner){
        background: none;
        border: none;
        color: #606266;
        padding: 0;
      }
      :deep(.el-select__caret){
        display: none;
      }
    }
  }
</style>
